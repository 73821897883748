.loading-wrapper{
	height: 20rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2rem;
}

.loading-text {
	color: #539165;
	font-weight: bold;
	font-family: sans-serif;
	font-size: 30px;
	padding-bottom: 8px;
	background:linear-gradient(currentColor 0 0) bottom left/0% 3px no-repeat;
	animation:c2 2s linear infinite;
  }

@keyframes c2 {
	to{
		background-size: 100% 3px
	}
}

.loading-shape {
    width: 40px;
    height: 40px;
    color:#f03355;
    background:
      linear-gradient(currentColor 0 0),
      linear-gradient(currentColor 0 0),
      linear-gradient(currentColor 0 0),
      linear-gradient(currentColor 0 0);
    background-size: 21px 21px;
    background-repeat:no-repeat;
    animation: sh5 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes sh5 {
   0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
   33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
   66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
   100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}